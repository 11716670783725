/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-max-props-per-line */
import * as React from 'react';
import Page from '../../uiToolkit/containers/page';
import { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter, RouteComponentProps, useHistory } from 'react-router';
import { ApplicationState } from '../../store';
import * as AuthActions from '../../store/authentication/actions';
import * as ProductLeaderboardStore from '../../store/productLeaderboard/ProductLeaderboard';
import CronDateDropdown from '../Common/CronDateDropdown/cronDateDropdown';
import { Message } from '../Common/Message/message';
import ReactGA from 'react-ga';
import { getLatestValidDate } from '../../uiToolkit/helpers/cronHelper';
import Loading from '../Common/Loading/loading';
import ProductEntry from './ProductEntry/ProductEntry';
import * as qs from 'qs';
import ReportingEntityDropdown from '../Common/ReportingEntityDropdown/ReportingEntityDropdown';
import PermissionHelper from '../../uiToolkit/helpers/PermissionHelper';
import { ScoringEntityTypes } from '../../store/product/types';
import { ScoreAreaType, SortOrder } from '../../store/authentication/types';

const mapState = (state: ApplicationState) => ({
  authentication: state.authentication,
  productLeaderboard: state.productLeaderboard,
});

const mapDispatch = {
  ...AuthActions.actionCreators,
  ...ProductLeaderboardStore.actionCreators,
};

const connector = connect(mapState, mapDispatch);

interface PropsType extends RouteComponentProps { children?: React.ReactNode }
type PropsFromRedux = ConnectedProps<typeof connector>

type IProps =
  PropsFromRedux
  & PropsType;



const ProductLeaderboard = (props: IProps) => {
  const [area, setArea] = useState<string>('');

  const [isAscending, setIsAscending] = useState(false);
  
  const [stateReportingEntity, setStateReportingEntity] = useState<string>(''); // used for the scoring entities dropdown
  const [stateDateKey, setStateDateKey] = useState<string>('');
  const tenantDetails = props.authentication && props.authentication.tenantDetails;
  const areas = (props.authentication && props.authentication.scoreAreas) || [];
  const scoringEntities = (props.authentication && props.authentication.scoringEntities) || [];

  const filteredAreasLookup = areas && Object.fromEntries(areas.filter(x => x.type === ScoreAreaType.product).map(b => [b.dataId, b]));

  const query = qs.parse(props.history.location.search.replace('?', ''));
  const queryDateKey = query.datekey && query.datekey.toString() || '';
  const queryReportingEntityKey: string = (query.reportingEntity && query.reportingEntity.toString()) || '';

  const { requestProductLeaderboard, requestAverageProductLeaderboard, setCurrentReportingEntity, setCurrentDate } = props;
  const { selectedReportingEntity, franchises, permissions } = props.authentication;

  const [productEntries, setProductEntries] = useState<ProductLeaderboardStore.ProductEntry[]>(props.productLeaderboard.productLeaderboard?.entries || []);

  const permHelper = new PermissionHelper(permissions);
  const history = useHistory();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    let stateDateKey: string = queryDateKey;
    let stateReportingEntityKey: string = queryReportingEntityKey;

    // Manage the date state
    if (queryDateKey.length > 0) {
      setCurrentDate(queryDateKey);
      setStateDateKey(queryDateKey);
    } else {
      const latestValidDate = getLatestValidDate(tenantDetails && tenantDetails.reportingWindow);
      stateDateKey = latestValidDate;
      setStateDateKey(latestValidDate);
      setCurrentDate(latestValidDate);
    }

    if (!queryReportingEntityKey) {
      stateReportingEntityKey = getDefaultSelectedEntity();
    }

    setStateReportingEntity(stateReportingEntityKey);

    if (!props.productLeaderboard.productLeaderboard) {
      getProductLeaderboard(stateDateKey, stateReportingEntityKey);
    }

    return () => {
      setStateReportingEntity('');
      setProductEntries([]);
    };
  }, []);

  useEffect(() => {
    if ((stateDateKey && stateReportingEntity)) {
      history.replace(`/product/leaderboard?datekey=${stateDateKey}&reportingEntity=${stateReportingEntity}`);
      getProductLeaderboard(stateDateKey, stateReportingEntity);
    }
  }, [stateDateKey, stateReportingEntity]);

  useEffect(() => {
    if (props.productLeaderboard.productLeaderboard?.entries) {
      setProductEntries(props.productLeaderboard.productLeaderboard?.entries);
    } else {
      setProductEntries([]);
    }
  }, [props.productLeaderboard.productLeaderboard]);

  const getDefaultSelectedEntity = () => {
    if (stateReportingEntity) {
      return String(stateReportingEntity);
    }

    if (permHelper.IsAdmin()) {
      return String(tenantDetails?.tenantId);
    }

    if (permHelper.IsOperationsManager()) {
      return String(franchises && franchises[0]);
    }

    if (permHelper.HasAccessToMultipleStores()) {
      return ScoringEntityTypes.Area;
    }

    return String(selectedReportingEntity);
  };

  const reportingEntityChanged = (selectedReportingEntity: string) => {
    if (scoringEntities && scoringEntities.map(x => x.dataId).includes(selectedReportingEntity)) {
      setCurrentReportingEntity(selectedReportingEntity); // if the option selected is a scoring entity, update the auth state
    }
    setStateReportingEntity(selectedReportingEntity);
  };


  const getProductLeaderboard = (reportingStartDate: string, stateReportingEntity: string) => {
    if (permHelper.HasAccessToMultipleStores() && stateReportingEntity === ScoringEntityTypes.Area) {
      requestAverageProductLeaderboard(reportingStartDate);
    } else if (stateReportingEntity.length > 0) {
      requestProductLeaderboard(reportingStartDate, stateReportingEntity);
    }
  };

  const dateChanged = (dateKey: string) => {
    const { setCurrentDate } = props;
    setCurrentDate(dateKey);
    setStateDateKey(dateKey);
    setArea('');
    setIsAscending(false);
  };

  const handleOrderChange = () => {
    setIsAscending(prevIsAscending => !prevIsAscending);
    sortProductLeaderboard(area, !isAscending);
  };

  const sortProductLeaderboard = (area: string, isAscending:boolean) => {

    setArea(area);

    let sortedData = productEntries!;
    if (!area) {
      sortedData = SortByTotalScore(sortedData, isAscending);
    } else {
      sortedData = SortByScoringArea(sortedData, area, isAscending);
    }
    setProductEntries(sortedData);
  };

  const filterByNameOrSku = (filterByValue: string) => {
    const entries = props.productLeaderboard.productLeaderboard?.entries!;
    if (filterByValue) {
      const filteredData = entries?.filter(product =>
        product.itemDescription.toLowerCase().includes(filterByValue.toLowerCase()) ||
        product.itemNumber.toLowerCase().includes(filterByValue.toLowerCase()));
      setProductEntries(filteredData);
    } else {
      setProductEntries(entries);
    }
  };

  const SortByTotalScore = (sortedData: ProductLeaderboardStore.ProductEntry[], isAscending:boolean): ProductLeaderboardStore.ProductEntry[] => {
    return !isAscending ? sortedData.sort((a, b) => b.totalScore - a.totalScore) : sortedData.sort((a, b) => a.totalScore - b.totalScore);
  };

  const SortByScoringArea = (sortedData: ProductLeaderboardStore.ProductEntry[], area: string, isAscending:boolean): ProductLeaderboardStore.ProductEntry[] => {
    return sortedData.sort((a, b) => {
      const a2 = a.scores.find(z => z.area === area);
      const b2 = b.scores.find(y => y.area === area);

      if (!a2 || !b2) {
        return 0;
      }
      return !isAscending ?  b2.rawScore - a2.rawScore :  a2.rawScore - b2.rawScore;
    });
  };

  const formatItemNumber = (itemNumber: string) => {
    return itemNumber.replace(/\//g, '_');
  };

  const hasLeaderboards = props.productLeaderboard.productLeaderboard?.entries;

  return (
    <Page mode=" leaderboard"
      pageNumber={1}>
      <div className="content podium">
        <div className="podiums">
          <h1>Product Leaderboard</h1>

        </div>
      </div>
      <div className="header-controls">
        <div className="header-wrapper">
          <h1>
            Product Leaderboard
          </h1>
          <div className='header-menu'>
            <div className="leaderboard-selector area-selector">
              <label htmlFor="leaderboards">
                <h5>Filter By</h5>
                <input
                  name="productName"
                  placeholder="Enter product name or SKU"
                  onChange={(e) => filterByNameOrSku(e.target.value)}>
                </input>
              </label>
            </div>
            {permHelper.HasAccessToMultipleStores() && (<div className="date-selector">
              <h5>Scoring Entity</h5>
              <ReportingEntityDropdown
                placeholderText='Select a scoring entity'
                selectedEntity={stateReportingEntity}
                isGrey
                onChange={(reportingEntity: string) => reportingEntityChanged(reportingEntity)}
              />
            </div>)}

            <div className="date-selector">
              <h5>Scoring Period</h5>
              <CronDateDropdown
                placeholderText="Scoring Period"
                cronString={tenantDetails?.reportingWindow || ''}
                currentDateKey={stateDateKey}
                onChange={(dk: string) => dateChanged(dk)}
                isGrey />
            </div>
            <div className="leaderboard-selector area-selector">
              <label htmlFor="leaderboards">
                <h5>Sort By</h5>
                <select
                  name="areas"
                  placeholder="Scoring Area"
                  value={area}
                  onChange={(e) => sortProductLeaderboard(e.target.value, isAscending)}>
                  <option value="">Orderly Score</option>

                  {areas && areas.filter((area) => area.type === ScoreAreaType.product).map((scoringArea) => (
                    <option
                      key={scoringArea.dataId}
                      value={scoringArea.dataId}
                    >
                      {scoringArea.name}
                    </option>
                  ))}
                </select>

 
                <div
                  className={`toggle-button ${!isAscending ? 'active' : ''}`}
                  onClick={handleOrderChange}
                >
                  <div className="toggle-button-circle"></div>
                  <span className="toggle-button-label">
                    {isAscending ? SortOrder.ASC : SortOrder.DESC}
                  </span>
                </div>
          

              </label>
            </div>

          </div>
        </div>
      </div>


      <div className="content leaderboard">
        <div className="leaderboards">

          {props.productLeaderboard.isLoading && (<Loading />)}

          {!props.productLeaderboard.isLoading && !hasLeaderboards && (<Message text="No leaderboards are currently available based on the filter set." />)}

          {!props.productLeaderboard.isLoading && hasLeaderboards && productEntries.map((product, idx) => {
            return (
              <ProductEntry
                onClick={() => history.push(`/product/score/${formatItemNumber(product.itemNumber)}?reportingEntity=${stateReportingEntity}&datekey=${stateDateKey}`)}
                key={idx}
                rank={idx + 1}
                maxScore={5}
                siteId={props.productLeaderboard.productLeaderboard?.siteId!}
                entry={product}
                tenantDetails={tenantDetails}
                scoringAreaLookup={filteredAreasLookup}
              />
            );
          })}
        </div>
      </div>

    </Page>
  );
};

export default connector(withRouter(ProductLeaderboard));


